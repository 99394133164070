import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { initialise } from './services';
// import { Container } from './components/container';
import { PreLaunch } from './components/pre-launch';
import './styles/global.pcss';

initialise()
    .then(() => {
        ReactDOM.render(
            <PreLaunch />, document.getElementById('app')
        );
    })
    .catch(error => {
        console.error('A fatal error occurred while initialising services', error); // tslint:disable-line no-console
        ReactDOM.render(
            <PreLaunch />, document.getElementById('app')
        );
    });
