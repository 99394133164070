import * as React from 'react';
import '../styles/components/pre-launch.pcss';

interface PreLaunchProps { }

interface PreLaunchState { }

export class PreLaunch extends React.Component<PreLaunchProps, PreLaunchState> {
    public constructor(props: PreLaunchProps) {
        super(props);

        this.setState({});
    }

    public render() {
        return (
            <div className="pre-launch-container">
                <div className="header-image" />
                <div className="header-container" />
                <div className="logo" />
                <div className="subtitle">020 3887 4116 • 109 High Street Hampton Hill, TW12  • Website launching soon</div>
                <div className="mobile-subtitle">Website launching soon</div>
            </div>
        );
    }

    public componentWillMount() {
        console.log('componentWillMount'); // tslint:disable-line no-console
    }

    public componentWillUnmount() {
        console.log('componentWillUnmount'); // tslint:disable-line no-console
    }

    public componentDidMount() {
        console.log('componentDidMount'); // tslint:disable-line no-console
    }
}
